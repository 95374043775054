.video-play-dialog .el-dialog__header {
  padding-bottom: 0px;
  border-bottom: 1px solid #e6e6e6;
}
.video-play-dialog .video-play-wrap {
  width: 100%;
  height: 500px;
}
.video-play-dialog .sure-btn {
  margin-top: 20px;
  padding: 10px 20px;
}
