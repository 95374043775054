@charset "UTF-8";
.meeting-prepare-wrapper {
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f2f3f7;
}
.meeting-prepare-wrapper .left-bd {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.meeting-prepare-wrapper .left-bd .base-info-card {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-hd-group {
        background: #dbe9ff;
        padding: 10px 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        line-height: 1;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-hd-group .hd-group-left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          line-height: 1;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-hd-group .hd-title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #303133;
          line-height: 1;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-hd-group .hd-tag {
          font-size: 14px;
          line-height: 1;
          padding: 0px 15px;
          height: 24px;
          line-height: 24px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          border-radius: 12px;
          margin-left: 10px;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-hd-group .hd-state-tag {
          border: 1px solid #409eff;
          color: #409eff;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-hd-group .hd-type-tag {
          border: 1px solid #8894f2;
          color: #8894f2;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-hd-group .hd-subject-tag {
          border: 1px solid #ffa112;
          color: #ffa112;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group {
        padding: 10px 30px 30px 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-top: 10px;
          margin-right: 60px;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-left .bd-ft-process {
            width: 90px;
            height: 90px;
            position: relative;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-left .bd-ft-process .bd-ft-process-count {
              position: absolute;
              top: 38%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 1;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-left .bd-ft-process .bd-ft-process-state {
              position: absolute;
              top: 62%;
              left: 50%;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #606266;
              line-height: 1;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-left .bd-ft-process .finish-count {
              color: #409eff;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-left .bd-ft-process .unfinished-count {
              color: #ff5959;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .row-cell {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          padding-left: 30px;
          margin-top: 20px;
          line-height: 1;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .row-cell .row-cell-title {
            color: #909399;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .row-cell .row-cell-title span {
              display: block;
              width: 70px;
              -moz-text-align-last: justify;
                   text-align-last: justify;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .row-cell .row-cell-desc {
            color: #303133;
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;
            text-align: left;
            white-space: wrap;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            font-size: 14px;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .row-cell .row-cell-desc .cell-desc-text {
              width: 90px;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .row-cell .row-cell-desc .cell-switch-button {
              padding: 0px;
              margin-left: 20px;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .row-cell .row-cell-desc .cell-switch-button .el-icon-sort {
                -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-mid {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-mid .row-cell-title span {
            width: 60px;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-right {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
}
.meeting-prepare-wrapper .left-bd .base-info-card .c-body-group .bd-right .row-cell-title span {
            width: 70px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group {
      margin-top: 20px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs {
        padding: 0px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__header {
          padding: 0px 20px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__content {
          padding: 20px;
          height: 540px;
          overflow: auto;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__content::-webkit-scrollbar {
            width: 3px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__content::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #d9d9d9;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__content::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: white;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__active-bar {
          display: none;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__nav-wrap::after {
          height: 1px;
          background-color: #e6e6e6;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__item {
          height: auto;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs .el-tabs__item.is-active {
          font-weight: bold;
          color: #409eff;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs__header {
        margin: 0px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-tabs__content {
        padding: 20px 0px;
        min-height: 200px;
        text-align: left;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-video-box .m-video-group {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-video-box .m-video-group .c-card-video-item {
          width: 200px;
          background: #ffffff;
          border: 1px solid #e6e6e6;
          padding: 10px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          position: relative;
          margin-right: 40px;
          margin-bottom: 30px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-video-box .m-video-group .c-card-video-item .video-item-img {
            width: 180px;
            height: 100px;
            -o-object-fit: cover;
               object-fit: cover;
            border: 1px solid #e6e6e6;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-video-box .m-video-group .c-card-video-item .video-item-mask {
            position: absolute;
            width: 180px;
            height: 100px;
            -o-object-fit: cover;
               object-fit: cover;
            background: #000000;
            opacity: 0.5;
            z-index: 1;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-video-box .m-video-group .c-card-video-item .video-item-name {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
            color: #31373d;
            line-height: 1;
            white-space: nowrap;
            /* 防止文字换行 */
            overflow: hidden;
            /* 隐藏溢出内容 */
            text-overflow: ellipsis;
            /* 显示省略号 */
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-video-box .m-video-group .c-card-video-item .video-icon {
            position: absolute;
            top: 40%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            z-index: 1;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-video-box .m-video-group .c-card-video-item .check-item {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 50%;
            text-align: center;
            -webkit-box-sizing: content-box;
                    box-sizing: content-box;
            z-index: 2;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-doc-box .m-doc-group {
        margin-top: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-doc-box .m-doc-group .c-card-doc-item {
          width: 200px;
          height: 70px;
          border: 1px solid #e6e6e6;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          position: relative;
          margin-right: 40px;
          margin-bottom: 30px;
          padding: 20px 10px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-doc-box .m-doc-group .c-card-doc-item .doc-check-item {
            -webkit-box-sizing: content-box;
                    box-sizing: content-box;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 50%;
            text-align: center;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-doc-box .m-doc-group .c-card-doc-item .doc-item-name {
            font-size: 14px;
            font-weight: 400;
            color: #31373d;
            margin-left: 10px;
            padding-right: 30px;
            max-width: 160px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 显示两行，超过的部分显示省略号 */
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .animation-card {
        cursor: pointer;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .animation-card:hover {
          -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
          -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
                  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
          border-radius: 4px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .card-item-checked {
        border-color: #409eff !important;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .card-item-normal {
        border-color: #e6e6e6 !important;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .card-item-normal .video-item-img {
          background: transparent;
          opacity: 1;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .el-icon-success {
        color: #409eff;
        font-size: 21px;
}
.meeting-prepare-wrapper .left-bd .left-meeting-group .m-box-hd {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #303133;
        padding-left: 10px;
        line-height: 1;
        border-left-color: #409eff;
        border-left-width: 4px;
        border-radius: 2px;
        border-left-style: solid;
}
.meeting-prepare-wrapper .right-bd {
    width: 440px;
    margin-left: 20px;
}
.meeting-prepare-wrapper .right-bd .start-meeting-button {
      width: 100%;
      -webkit-box-shadow: 0px 1px 9px 0px rgba(64, 158, 255, 0.83);
              box-shadow: 0px 1px 9px 0px rgba(64, 158, 255, 0.83);
      background: #409eff;
      font-size: 16px;
      font-weight: bold;
}
.meeting-prepare-wrapper .right-bd .start-meeting-button .el-icon-thumb {
        font-size: 18px;
}
.meeting-prepare-wrapper .right-bd .history-list-group {
      margin-top: 30px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
}
.meeting-prepare-wrapper .right-bd .history-list-group .hd-group {
        margin: 0px 20px;
        padding: 15px 0px;
        border-bottom: 1px solid #e6e6e6;
        line-height: 1;
        font-size: 16px;
        font-weight: bold;
        color: #303133;
        text-align: left;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main {
        height: 670px;
        padding: 0 20px 60px 20px;
        overflow: auto;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main::-webkit-scrollbar {
          width: 3px;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #d9d9d9;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0;
          background: white;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card {
          background: #ffffff;
          border: 1px solid #e5e5e5;
          -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
                  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
          margin-top: 20px;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card .m-card-hd {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            border-bottom: 1px solid #e6e6e6;
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            font-size: 14px;
            font-weight: 400;
            color: #303133;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card .m-card-hd .state-doing {
              color: #ff5959;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card .m-card-hd .state-finish {
              color: #909399;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card .m-card-bd {
            padding: 0 20px 20px 20px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #303133;
            text-align: left;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card .m-card-bd .m-bd-cell {
              margin-top: 20px;
              line-height: 1;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card .m-card-ft {
            border-top: 1px solid #e6e6e6;
            padding: 10px 20px;
            text-align: right;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-main .m-history-card .m-card-ft .el-button {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              padding: 10px 12px;
}
.meeting-prepare-wrapper .right-bd .history-list-group .history-list-empty {
        height: 670px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
}
